<template>
  <div class="page-main-box">
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 搜索头 -->
      <search-header
        style="display:none;"
        :schemaData="schema"
        @getDataList="handleFormSearch"
      />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table :dataSource="tableDataSource" height="auto">
          <p-t-column type="seq" width="160" title="序号" />
          <p-t-column field="flow_no" min-width="236" title="充值订单ID" />
          <p-t-column
            field="amount"
            min-width="150"
            title="充值金额"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.amount / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>
          <p-t-column title="充值时间" field="recharge_time" min-width="230">
            <template v-slot="{ row }">
              {{
                row.recharge_time &&
                  formatToDateTime(row.recharge_time, "yyyy-MM-dd hh:mm:ss")
              }}
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRaw } from "vue";
import { useRoute } from "vue-router";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { useGasRechargePageApi } from "@/apis/oil";
import useSearchForm from "@/hooks/UseSearchForm";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useTableHeight from "@/hooks/useTableHeight";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "application-records",
  components: {},
  setup() {
    const route = useRoute();
    const gasRechargePageApi = useGasRechargePageApi();

    // table高度
    const tableHeight = ref();

    // 列表数据
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const { filter, handleFormSearch } = useSearchForm(searchHeaderOptions);

    // 获取列表数据
    Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo,
          company_id: route.query.id,
          card_no: route.query.card_no,
          sp_code: route.query.sp_code
        };
        let [, res] = await $await(gasRechargePageApi(data));

        return useTableDataRecords(res);
      }
    });

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      tableDataSource,
      formatToDateTime,
      schema: searchHeaderOptions,
      handleFormSearch
    };
  }
};
</script>

<style lang="less" scoped></style>
