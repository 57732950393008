import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/ops");

// 企业油卡账户
export const useGasCardPageApi = basicApi({
  url: "/gas/card/page",
  withCredentials: true
});
// 企业油卡账户详情
export const useGasCardDetailApi = basicApi({
  url: "/gas/card/detail",
  withCredentials: true
});

// 企业油卡账户充值记录
export const useGasRechargePageApi = basicApi({
  url: "/gas/recharge/page",
  withCredentials: true
});

// 消费订单
export const useGasOrderPageApi = basicApi({
  url: "/gas/order/page",
  withCredentials: true
});

// 消费订单导出
export const useGasOrderPageExportApi = basicApi({
  url: "/gas/order/export",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});

// 消费订单详情
export const useGasOrderDetailApi = basicApi({
  url: "/gas/order/detail",
  withCredentials: true
});

// 划拨记录
export const useGasTransferPageApi = basicApi({
  url: "/gas/transfer/page",
  withCredentials: true
});
